<template>
  <v-btn
    v-if="itSees"
    class="ml-2"
    min-width="0"
    text
    to="/cart"
  >
    <v-badge
      color="green"
      :content="totalItemsOnCart"
      :value="totalItemsOnCart"
    >
      <v-icon>mdi-cart</v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import { rolesIds } from '@/roles'

export default {
  name: 'CartIcon',
  computed: {
    totalItemsOnCart () {
      return this.$store.getters.getTotalItemsOnCart
    },
    user () {
      return this.$store.getters.getUserLogged
    },
    itSees () {
      return [
        rolesIds.entityAdmin,
      ].includes(this.user.user_role_id)
    },
  },
}
</script>

<style scoped>

</style>
