<template>
  <v-menu
    class="shadow-lg"
    left
    bottom
  >
    <template v-slot:activator="{ on }">
      <v-btn
        small
        class="ml-2"
        text
        style="margin-bottom: 5px;"
        v-on="on"
      >
        <v-avatar
          height="20px"
          width="20px"
        >
          <img
            v-if="lang === 'it'"
            src="italy-flag.jpg"
          >
          <img
            v-if="lang === 'en'"
            src="en-flag.png"
          >
        </v-avatar>
      </v-btn>
    </template>

    <v-list>
      <v-list-item  class="d-flex justify-center">
        <v-btn
          icon
          small
          @click="changeLanguage('it')"
        >
          <v-avatar size="30">
            <img src="italy-flag.jpg">
          </v-avatar>
        </v-btn>
      </v-list-item>
      <v-list-item class="d-flex justify-center">
        <v-btn
          icon
          small
          @click="changeLanguage('en')"
        >
          <v-avatar size="30">
            <img src="en-flag.png">
          </v-avatar>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import LanguageRepository from '../../api/LanguageRepository'
import { getCookie } from '@/helpers/helpers'

export default {
  name: 'ChangeLangBtn',
  data () {
    return {
      lang: null,
    }
  },
  mounted () {
    this.lang = getCookie('lang')
    if (!this.lang) this.lang = 'en'
  },
  methods: {
    changeLanguage (lang) {
      this.lang = lang
      LanguageRepository.change(lang)
        .then(() => {
          return LanguageRepository.language()
        })
        .then(({ data }) => {
          const general = data.general
          delete data.general
          data = {
            ...data,
            ...general,
          }
          this.$i18n.setLocaleMessage(lang, data)
          this.$i18n.locale = lang
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style scoped>

</style>
