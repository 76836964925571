<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="value">
        mdi-view-quilt
      </v-icon>

      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
    >
      {{ $t($route.name.toLowerCase()) | capitalize }}
    </v-toolbar-title>
    <v-spacer />

    <div class="mx-3" />

    <dashboard-icon />

    <cart-icon />

    <change-lang-btn />

    <v-menu
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          min-width="0"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          @click="logout"
        >
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>

// Utilities
import { mapState, mapMutations } from 'vuex'
import CartIcon from '@/views/dashboard/dashboardComponents/core/BarIcons/CartIcon'
import DashboardIcon from '@/views/dashboard/dashboardComponents/core/BarIcons/DashboardIcon'
import { logoutUser } from '@/api/AuthRepository'
import ChangeLangBtn from '@/components/ChangeLangBtn/ChangeLangBtn'

export default {
  name: 'DashboardCoreAppBar',
  components: { ChangeLangBtn, DashboardIcon, CartIcon },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapState(['drawer']),
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    logout () {
      logoutUser()
      this.$store.dispatch('setUserLogged', null)
      this.$router.push('/login')
    },
  },

}
</script>
