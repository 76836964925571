<template>
  <v-btn
    v-if="itSees"
    class="ml-2"
    min-width="0"
    text
    to="/dashboard"
  >
    <v-icon>mdi-view-dashboard</v-icon>
  </v-btn>
</template>

<script>
import { rolesIds } from '@/roles'

export default {
  name: 'DashboardIcon',
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
    itSees () {
      return [
        rolesIds.stockManager,
      ].includes(this.user.user_role_id)
    },
  },
}
</script>

<style scoped>

</style>
